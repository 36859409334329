<template>
  <div class="region-content content">
    <div class="content-search">
      <ui-input @input="searchList" search :placeholder="$t('search')" v-model="filterData.search" />
      <div class="content-search__icons">
        <img @click="visibleFilter = !visibleFilter" :src="filterColor" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img :src="sortColor" alt="">
          </template>
          <template #body>
            <div class="sort">
              <p
                  @click="changeSort(item)"
                  v-for="(item, key) in sortData"
                  :class="['sort__item', { active: item.value === sortBy?.value }]"
                  :key="key"
              >
                {{ item.text }}
                <img v-if="item.value === sortBy?.value" src="@/assets/svg/red-check.svg" alt="">
              </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <search-filter
        v-if="visibleFilter"
        @submit="submit"
        @reset="reset"
    >
      <div class="filter__row-wrap first">
        <ui-select v-model="filterData.ratingLevelVal" :options="getRatinglevel" :label="$t('rating-level')" />
      </div>
      <div class="filter__row-wrap">
        <ui-input v-model="filterData.numberFrom" class="input-req" :label="$t('number-of-requests-from')" v-mask="'########'" />
        <ui-input v-model="filterData.numberTo" class="input-req" :label="$t('number-of-requests-to')" v-mask="'########'"/>
        <ui-input :label="$t('damage-amount')" />
      </div>
    </search-filter>
    <ui-loader v-model="visibleLoader" />
    <div v-if="!visibleLoader" class="region-content-body content-body">
      <div class="content-body__header">
        <p class="content-body__header-description" @click="visibleDescriptionAlert = !visibleDescriptionAlert"><img src="@/assets/svg/icon-attention-grey.svg" />{{ $t('description-of-indicators') }}</p>
        <p class="content-body__header-title">{{ $t('regions-open-length', {form: dataRegions.length, to: totalLength}) }}</p>
        <div class="content-body__header-icons">
          <p @click="visibleDescriptionAlert = !visibleDescriptionAlert"><img src="@/assets/svg/icon-attention-grey.svg" />{{ $t('description-of-indicators') }}</p>
          <img @click="type = 'single'" :src="singleIconColor" alt="">
          <img @click="type = 'double'" :src="doubleIconColor" alt="">
        </div>
      </div>
      <ui-loader v-model="visibleLoader" />
      <div :class="['content-body__cards', { double: type === 'double' }]" v-if="!visibleLoader">
        <template v-if="dataRegions.length">
          <data-region-card :edit="edit" :data="card" :type="type" v-for="(card, key) in dataRegions" :key="key" @updateRegionImage="updateRegionImage($event)"/>
        </template>
        <p v-else class="empty-list">{{ $t('list-is-empty') }}</p>
      </div>
      <ui-button v-if="visibleLoadMore" @click="loadMore" color="white">
        {{ $t('show-more') }}
      </ui-button>
    </div>
    <ui-alert v-model="visibleDescriptionAlert" showClose>
      <h5 class="alert__title">{{ $t('description-of-indicators') }}</h5>
      <div class="alert__line"></div>
      <div class="alert__item">
        <p v-html="$t('link')"></p>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="visibleDescriptionAlert = !visibleDescriptionAlert">{{ $t('understood') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiAlert from "@/components/ui/UiAlert.vue";

const defaultSearch = {
  ratingLevelVal: { name: 'Все', value: '' },
  search: '',
  numberFrom: '',
  numberTo: '',
  sort: { text: 'Число баллов', value: 'score' }
}

export default {
  name: "RegionContent",
  components: {
    UiAlert,
    UiButton: () => import('@/components/ui/UiButton'),
    SearchFilter: () => import("@/components/common/SearchFilter"),
    UiLoader: () => import('@/components/ui/UiLoader'),
    UiInput: () => import("@/components/ui/UiInput"),
    UiMenu: () => import("@/components/ui/UiMenu"),
    UiSelect: () => import("@/components/ui/UiSelect"),
    DataRegionCard: () => import("@/components/template/data-region/DataRegionCard.vue")
  },

  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      visibleDescriptionAlert: false,
      visibleLoader: true,
      visibleFilter: false,
      visibleSort: false,
      sortBy: { text: this.$t('by-region'), value: 'name' },
      visibleLoadMore: true,
      totalLength: 0,
      sortData: [
        { text: this.$t('by-region'), value: 'name' },
        { text: this.$t('by-number-of-requests-1'), value: 'orders' },
        { text: this.$t('by-amount-of-damage'), value: 'damage' },
      ],
      type: 'single',
      getRatinglevel: [
        { name: this.$t('all'), value: '' },
        { name: this.$t('does-not-participate'), value: 'Unknown' },
        { name: this.$t('low'), value: 'Low' },
        { name: this.$t('medium'), value: 'Medium' },
        { name: this.$t('high'), value: 'High' },
      ],
      filterData: { ...defaultSearch }
    }
  },

  computed: {
    ...mapGetters(['dataRegions']),
    filterColor() {
      return this.visibleFilter ? require('@/assets/svg/search/red-filter.svg') : require('@/assets/svg/search/black-filter.svg')
    },

    sortColor() {
      return this.visibleSort ? require('@/assets/svg/search/red-sort.svg') : require('@/assets/svg/search/black-sort.svg')
    },

    singleIconColor() {
      if(this.type === 'single') {
        return require('@/assets/svg/regionRanking/icon-red-single.svg')
      }
      return require('@/assets/svg/regionRanking/icon-black-single.svg')
    },

    doubleIconColor() {
      if(this.type === 'double') {
        return require('@/assets/svg/regionRanking/icon-red-double.svg')
      }
      return require('@/assets/svg/regionRanking/icon-black-double.svg')
    }
  },

  methods: {

    ...mapActions(['regions', 'updateRegions']),
    ...mapMutations(['setDataRegions']),

    callApi() {
      this.visibleLoader = true
      this.regions({
        skip: 0,
        take: 20,
        search: this.filterData.search || null,
        rating: this.filterData.ratingLevelVal.value || null,
        ordersFrom: this.filterData.numberFrom || null,
        ordersTo: this.filterData.numberTo || null,
        sort: this.sortBy?.value || null,
        order: 'ASC'
      }).then((res) => {
        this.totalLength = res.meta.total
        this.visibleLoader = false
        this.setDataRegions(res.data)
        if (res.data.length !== 20) {
          this.visibleLoadMore = false
        }
      })
    },
    updateList() {
      this.visibleLoader = true
      this.regions({
        take: 20,
        search: this.filterData.search || null,
        rating: this.filterData.ratingLevelVal.value || null,
        ordersFrom: this.filterData.numberFrom || null,
        ordersTo: this.filterData.numberTo || null,
        sort: this.sortBy?.value || null,
        order: 'ASC'
      }).then((res) => {
        this.totalLength = res.meta.total
        this.visibleLoader = false
        this.setDataRegions(res.data)
        if (res.data.length !== 20) {
          this.visibleLoadMore = false
        }
      })
    },
    changeSort(val) {
      this.visibleSort = false
      this.sortBy = val
      this.updateList()
    },
    searchList() {
      console.log(this.filterData);
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        !this.filterData.search ? this.callApi() : this.updateList()
      }, 500)
    },
    submit() {
      this.updateList()
      this.visibleLoader = true
      this.visibleFilter = false
    },
    reset() {
      this.visibleFilter = true
      this.filterData = { ...defaultSearch }
      this.callApi()
    },
    loadMore() {
      this.regions({
        skip: this.dataRegions.length,
        take: 20,
        search: this.filterData.search || null,
        rating: this.filterData.ratingLevelVal.value || null,
        ordersFrom: this.filterData.numberFrom || null,
        ordersTo: this.filterData.numberTo || null,
        sort: this.sortBy?.value || null,
        order: 'ASC'
      }).then((res) => {
        this.totalLength = res.meta.total
        this.visibleLoader = false
        this.setDataRegions(this.$store.state.regionsStore.dataRegions.concat(res.data))
        if (res.data.length !== 20) {
          this.visibleLoadMore = false
        }
      })
    },

    updateRegionImage(data) {
      this.updateRegions(data).then(() => {
        this.visibleLoader = true
        this.updateList()
      })
    }
  },

  async mounted() {
    this.filterData.ratingLevelVal.name = this.$t('all')
    this.filterData.sort.text = this.$t('number-of-points')
    this.callApi()
    window.innerWidth < 768 ? this.type = 'double' : this.type = 'single'

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        this.type = 'double'
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  ::v-deep .filter__row {
    display: flex !important;

    .input-req {

      @media (min-width: 992px) {
        max-width: 207px;
      }
    }
    .first {
      @media (min-width: 992px) {
        max-width: 433px;
      }
    }
  }
}

.content-search {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    border: none;
    padding-bottom: 0;
    gap: 20px;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
      color: red;
    }
  }
}

.content-body {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    &-description {
      display: none;
      align-items: center;
      gap: 5px;
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      @media (max-width: 768px) {
        display: flex;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }

    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #9A9A9A;
    }

    &-icons {
      display: flex;
      gap: 20px;

      @media (max-width: 768px) {
        display: none;
      }

      img {
        cursor: pointer;
        width: 32px;
        height: 32px;
      }

      p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #9A9A9A;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;

    &.double {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }

    .empty-list {
      margin: 100px auto;
    }
  }
}

::v-deep .single-card__right-count {
  @media (max-width: 992px) {
    width: auto !important;
  }
}

::v-deep .single-card__right-edit {
  @media (max-width: 992px) {
    margin-left: 0 !important;
  }
}

::v-deep .single-card__right {
  @media (max-width: 768px) {
    flex-direction: column !important;
    gap: 15px !important;
    width: 50% !important;
  }
}
.alert {
  &__title {
    color: #1B1A1F;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

  &__line {
    background: #F5F5F5;
    width: 100%;
    height: 1px;
  }

  &__item {
    display: flex;

    p {
      color: #1B1A1F;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      a {
        color: #CE2121;
      }
    }
  }
}
::v-deep .ui-alert {
  background-color: #FFFFFF;
  gap: 20px;
  max-width: 400px;
  width: 100%;
}
::v-deep .red-link {
  color: #CE2121;
}
</style>
