<template>
  <div class="region">
    <div class="region__sidebar">
      <h1 @click="$router.push('/admin/region')">{{ $t('rating-of-regions') }}</h1>
      <div class="region__sidebar-items">
        <p @click="$route.path === '/admin/region' ? edit = !edit : ''" :class="['region-item', {active: edit}]" :style="{cursor: $route.path === '/admin/region' ? 'pointer' : 'not-allowed'}">{{ $t('edit-regions') }}</p>
        <p class="region-item">{{ $t('formulas-for-rating-calculation') }}</p>
<!--        <router-link to="/admin/region/add" class="region-item" @click.native="edit = false">Добавить регион</router-link>-->
      </div>
      <ui-menu v-model="visibleSettings" class="sidebar__ui-menu">
        <template #activator>
          <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="region__sidebar-settings" />
        </template>
        <template #body>
          <div class="sort">
            <p @click="$route.path === '/admin/region' ? edit = !edit : '', visibleSettings = !visibleSettings" :class="['sidebar__ui-menu-item', {active: edit}]">{{ $t('edit-regions') }}</p>
            <p class="sidebar__ui-menu-item">{{ $t('formulas-for-rating-calculation') }}</p>
<!--            <router-link to="/admin/region/add" class="sidebar__ui-menu-item" @click.native="edit = false, visibleSettings = !visibleSettings">Добавить регион</router-link>-->
          </div>
        </template>
      </ui-menu>
    </div>
    <RegionContent :edit="edit" v-if="$route.path === '/admin/region'"/>
    <router-view></router-view>
  </div>
</template>

<script>
import RegionContent from "@/views/admin/RegionContent";
import UiMenu from "../../components/ui/UiMenu.vue";
export default {
  name: "Region",
  components: {UiMenu, RegionContent},

  data() {
    return {
      edit: false,
      links: [
        { id: 0, text: this.$t('edit-regions'), path: '/' },
        { id: 1, text: this.$t('formulas-for-rating-calculation'), path: '' },
      ],
      visibleSettings: false,
    }
  },

  computed: {
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  },
}
</script>

<style lang="scss" scoped>
 .region {
   background: #F5F5F5;
   min-height: 100vh;
   height: 100%;
   padding: 150px 50px 0 50px;
   display: flex;
   gap: 74px;

   @media (max-width: 1200px) {
     flex-direction: column;
     gap: 30px;
     padding: 99px 20px 50px 20px;
   }

   &__sidebar {
     display: flex;
     flex-direction: column;
     gap: 48px;

     @media (max-width: 1200px) {
       flex-direction: row;
       justify-content: space-between;

     }

     &-settings {
       display: none;

       @media (max-width: 1200px) {
         display: inline-block;
       }
     }

     h1 {
       font-size: 20px;
       line-height: 22px;
       color: #343432;
       cursor: pointer;
     }

     &-items {
       display: flex;
       flex-direction: column;
       gap: 30px;

       @media (max-width: 1200px) {
         display: none;
       }

       .region-item {
         font-size: 16px;
         line-height: 15px;
         color: #343432;
         white-space: nowrap;
         cursor: pointer;

         &.active {
           color: #CE2121;
         }
       }
     }
   }
 }
.sidebar__ui-menu {
  z-index: 11 !important;

  &-item {
    font-size: 16px;
    line-height: 15px;
    color: #343432;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      color: #CE2121;
    }
  }
}
.router-link-active {
  color: #CE2121 !important;
}
</style>
